var render = function () {
  var _vm$assetEngines$engi;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('EngineDetailsStyled', [_c('div', [_c('h3', [_vm._v(_vm._s(_vm.$t('enginesManager.heading')))])]), _vm._l(_vm.assetEngines.engines, function (engine, index) {
    return _c('EngineManagerStyled', {
      key: engine.id
    }, [_vm.editingEngineIndex !== index ? _c('div', [_c('h4', [_vm._v(_vm._s(_vm.$t('enginesManager.nthEngineHeading', {
      nth: index + 1
    })))]), _c('InputField', {
      attrs: {
        "labelWidth": 15
      },
      scopedSlots: _vm._u([{
        key: "input",
        fn: function fn() {
          return [_c('input', {
            attrs: {
              "type": "text",
              "disabled": true
            },
            domProps: {
              "value": engine.serialNumber
            }
          })];
        },
        proxy: true
      }, {
        key: "label",
        fn: function fn() {
          return [_vm._v(_vm._s(_vm.$t('enginesManager.serialNumber.label')))];
        },
        proxy: true
      }], null, true)
    }), engine.ecu && engine.ecu.partNumber ? _c('InputField', {
      attrs: {
        "labelWidth": 15
      },
      scopedSlots: _vm._u([{
        key: "input",
        fn: function fn() {
          return [_c('input', {
            attrs: {
              "type": "text",
              "disabled": true
            },
            domProps: {
              "value": engine.ecu.partNumber
            }
          })];
        },
        proxy: true
      }, {
        key: "label",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(_vm.$t('enginesManager.ecu.partNumber')) + " ")];
        },
        proxy: true
      }], null, true)
    }) : _vm._e(), _c('InputField', {
      attrs: {
        "labelWidth": 15
      },
      scopedSlots: _vm._u([{
        key: "input",
        fn: function fn() {
          return [_c('input', {
            attrs: {
              "type": "text",
              "disabled": true
            },
            domProps: {
              "value": engine.make
            }
          })];
        },
        proxy: true
      }, {
        key: "label",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(_vm.$t('enginesManager.make.name')) + " ")];
        },
        proxy: true
      }], null, true)
    }), _c('InputField', {
      attrs: {
        "labelWidth": 15
      },
      scopedSlots: _vm._u([{
        key: "input",
        fn: function fn() {
          return [_c('input', {
            attrs: {
              "type": "text",
              "disabled": true
            },
            domProps: {
              "value": engine.model.name
            }
          })];
        },
        proxy: true
      }, {
        key: "label",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(_vm.$t('enginesManager.model.name')) + " ")];
        },
        proxy: true
      }], null, true)
    }), _c('InputField', {
      attrs: {
        "labelWidth": 15
      },
      scopedSlots: _vm._u([{
        key: "input",
        fn: function fn() {
          return [_c('input', {
            attrs: {
              "type": "text",
              "disabled": true
            },
            domProps: {
              "value": _vm.titleCase(engine.energySource)
            }
          })];
        },
        proxy: true
      }, {
        key: "label",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(_vm.$t('enginesManager.energy.source')) + " ")];
        },
        proxy: true
      }], null, true)
    }), _c('EngineManagerActionsStyled', [_c('ButtonSolid', {
      attrs: {
        "disabled": _vm.editingEngineIndex
      },
      on: {
        "click": function click($event) {
          return _vm.showEngineForm(index + 1);
        }
      }
    }, [[_vm._v(_vm._s(_vm.$tc('enginesManager.updateEngine')))], _c('Edit2Icon', {
      staticClass: "edit-icon"
    })], 2)], 1)], 1) : _vm.editingEngineIndex === index ? _c('div', [_c('EngineForm', {
      attrs: {
        "engine": _vm.cloneEngine(engine)
      },
      on: {
        "on-cancel": _vm.closeForm,
        "on-save": _vm.refreshEngines
      }
    })], 1) : _vm._e()]);
  }), _c('EngineManagerActionsStyled', [_c('ButtonSolid', {
    staticClass: "add-action",
    attrs: {
      "disabled": _vm.editingEngineIndex
    },
    on: {
      "click": _vm.addNewAssetEngine
    }
  }, [[_vm._v(" " + _vm._s(((_vm$assetEngines$engi = _vm.assetEngines.engines) === null || _vm$assetEngines$engi === void 0 ? void 0 : _vm$assetEngines$engi.length) === 0 ? _vm.$t('enginesManager.addEngine') : _vm.$t('enginesManager.addAnotherEngine')) + " ")], _c('PlusCircleIcon', {
    staticClass: "add-icon"
  })], 2)], 1), _c('div', {
    staticClass: "button-group"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }