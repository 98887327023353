<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <div v-else>
    <header>
      <h4>{{ editEngine?.id ? $tc('enginesManager.updateEngine') : $tc('enginesManager.addEngine') }}</h4>
    </header>
    <div>
      <InputField :labelWidth="15">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.editEngine.serialNumber.$model" :required="true" :disabled="editEngine.id" />
        </template>
        <template v-slot:label>{{ $tc('enginesManager.serialNumber.label') }}*</template>
        <template v-slot:errors v-if="$v.editEngine.serialNumber.$dirty && !$v.editEngine.serialNumber.required">
          {{ $tc('enginesManager.serialNumber.validations.required') }}
        </template>
      </InputField>
      <InputField v-if="isPartNumberVisible" :labelWidth="15">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.editEngine.ecu.partNumber.$model" :disabled="isPartNumberPresent" />
        </template>
        <template v-slot:label>{{ $tc('enginesManager.ecu.partNumber') }}</template>
      </InputField>
      <StyledDropDown>
        <div class="type">
          <div class="type-label">{{ $t('enginesManager.make.name') }}*</div>
          <Multiselect
            class="multiselect"
            :value="selectedMake"
            :placeholder="$t('placeholders.selectOption')"
            track-by="id"
            label="code"
            :options="engineMake"
            :searchable="true"
            :multiple="false"
            :loading="false"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="300"
            :limit="3"
            :max-height="200"
            :show-no-results="false"
            :hide-selected="true"
            :show-labels="false"
            @select="setMake($event)"
          />
          <div class="add-icon" @click="showEngineMakeOverlay">
            <PlusCircleIcon />
          </div>
        </div>
      </StyledDropDown>
      <p class="errors" v-if="$v.editEngine.makeId.$dirty && !$v.editEngine.makeId.required">
        {{ $tc('messages.required') }}
      </p>
      <StyledDropDown>
        <div class="type">
          <div class="type-label">{{ $tc('enginesManager.model.name', 1) }}*</div>
          <Multiselect
            class="multiselect"
            :value="selectedModel"
            :placeholder="$t('placeholders.selectOption')"
            track-by="id"
            label="name"
            :options="engineModels"
            :searchable="true"
            :multiple="false"
            :loading="false"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="300"
            :limit="3"
            :max-height="200"
            :show-no-results="false"
            :hide-selected="true"
            :show-labels="false"
            @select="setModel($event)"
          />
          <div class="add-icon" @click="showEngineModelOverlay">
            <PlusCircleIcon />
          </div>
        </div>
      </StyledDropDown>
      <p class="errors" v-if="$v.editEngine.model.id.$dirty && !$v.editEngine.model.id.required">
        {{ $tc('messages.required') }}
      </p>
      <StyledDropDown>
        <div class="type">
          <div class="type-label">{{ $tc('enginesManager.energy.source', 1) }}*</div>
          <Multiselect
            class="multiselect"
            :value="selectedEnergySource"
            :placeholder="$t('placeholders.selectOption')"
            track-by="source"
            label="name"
            :options="mappedEnergySources"
            :searchable="true"
            :multiple="false"
            :loading="false"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="300"
            :limit="3"
            :max-height="200"
            :show-no-results="false"
            :hide-selected="true"
            :show-labels="false"
            @select="setEnergySource($event)"
          />
        </div>
      </StyledDropDown>
      <p class="errors" v-if="$v.editEngine.energySource.$dirty && !$v.editEngine.energySource.required">
        {{ $tc('messages.required') }}
      </p>
    </div>
    <EngineActionsStyled>
      <ButtonSolid :disabled="isInvalid || isNotDirty" @click="confirmSave" class="add-action">
        {{ $t('enginesManager.saveEngine') }}
      </ButtonSolid>
      <ButtonSolid @click="cancelForm" class="add-action">
        {{ $tc('actions.cancel') }}
      </ButtonSolid>
      <p v-if="pageErrors">
        <span class="error">{{ $tc('error') }}: </span> {{ pageErrors }}
      </p>
    </EngineActionsStyled>
  </div>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'
import { PlusCircleIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { flexCenter } from '@styles/mixins'
import { InputField, TalpaLoaderWrapper } from '@common/components'
import { ButtonSolid } from '@styles/buttons'
import ASSET_UPDATE_ENGINES_MUTATION from '#/graphql/assetEngines/updateEngineDetails.gql'
import ASSET_CREATE_ENGINES_MUTATION from '#/graphql/assetEngines/createEngineDetails.gql'
import ASSET_ENGINES_MAKE_QUERY from '#/graphql/assetEngines/EngineMake.gql'
import ASSET_ENGINES_MODELS_QUERY from '#/graphql/assetEngines/EngineModels.gql'
import ASSET_ENERGY_SOURCE_QUERY from '#/graphql/assetEngines/EnergySource.gql'

const StyledDropDown = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 30rem;
  .type {
    width: 30rem;
    display: flex;
    align-items: center;
    background: ${p => chroma(p.theme.colors.black).alpha(0.6).css()};
    padding: 0.25rem;
    .type-label {
      ${flexCenter}
      left: 0.25rem;
      top: 0.25rem;
      color: ${p => p.theme.colors.archonBlue};
      width: 20rem;
      height: 2rem;
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
      padding: 0.25rem;
    }
    .basic-select {
      max-width: 20rem;
      margin-left: 1rem;
    }
  }
  .add-icon {
    ${flexCenter}
    width: 80px;
    height: 40px;
    align-items: center;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
    cursor: pointer;
    &:hover {
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
    }
  }
`

const EngineActionsStyled = styled('div')`
  display: inline-block;
  margin-bottom: 1rem;
  .add-action {
    display: inline-block;
    margin-right: 10px;
    width: fit-content;
    padding: 0 0.5rem;
    height: 40px;
    cursor: pointer;
    > div {
      margin-right: 8px;
    }
  }
  .add-icon {
    padding-left: 0.2rem;
  }
  .error {
    color: red;
  }
`

export default {
  props: {
    engine: {
      type: Object,
    },
  },
  components: {
    EngineActionsStyled,
    StyledDropDown,
    InputField,
    Multiselect,
    TalpaLoaderWrapper,
    ButtonSolid,
    PlusCircleIcon,
  },
  mixins: [validationMixin],
  validations: {
    editEngine: {
      serialNumber: {
        required,
      },
      ecu: {
        partNumber: {},
      },
      makeId: {},
      make: {
        required,
      },
      model: {
        required,
        id: {
          required,
        },
      },
      energySource: {
        required,
      },
    },
  },
  data() {
    return {
      isLoading: false,
      pageErrors: '',
      editEngine: this.engine,
      engineMake: [],
      engineModels: [],
      energySources: [],
    }
  },
  beforeDestroy() {
    this.$v.editEngine.$reset()
    this.$emit('on-cancel', this.engine)
  },
  computed: {
    isInvalid() {
      return (
        this.$v.editEngine.serialNumber.$invalid ||
        this.$v.editEngine.makeId.$invalid ||
        this.$v.editEngine.model.id.$invalid ||
        this.$v.editEngine.energySource.$invalid
      )
    },
    isNotDirty() {
      return !this.$v.$anyDirty
    },
    selectedMake() {
      return this.engineMake?.find(make => make.code === this.editEngine?.make)
    },
    selectedModel() {
      return this.engineModels?.find(model => model.id === this.editEngine?.model?.id)
    },
    selectedEnergySource() {
      return this.mappedEnergySources?.find(mappedEnergySource => mappedEnergySource.source === this.editEngine?.energySource)
    },
    isPartNumberVisible() {
      // part number is visible only for new engines
      if (!this.editEngine?.id) {
        return true
      }
      // but for existing engines, part number is visible only if part number is present in the engine
      if (this.editEngine?.id && this.editEngine?.ecu?.partNumber) {
        return true
      }
      return false
    },
    isPartNumberPresent() {
      return this.editEngine?.id && this.editEngine?.ecu?.partNumber !== ''
    },
  },
  methods: {
    showEngineMakeOverlay() {
      this.$root.$emit('activateOverlay', 'CreateEngineMakeOverlay', undefined, () => this.$apollo.queries.engineMake.refetch())
    },
    showEngineModelOverlay() {
      this.$root.$emit('activateOverlay', 'CreateEngineModelOverlay', undefined, () => this.$apollo.queries.engineModels.refetch())
    },
    setMake(selectedMake) {
      this.$v.editEngine.make.$model = selectedMake?.code
      this.editEngine.makeId = selectedMake?.id
    },
    setModel(selectedModel) {
      this.editEngine.model = selectedModel
      this.$v.editEngine.model.id.$model = selectedModel?.id
    },
    setEnergySource(selectedEnergySource) {
      this.$v.editEngine.energySource.$model = selectedEnergySource?.source
    },
    cancelForm() {
      if (!this.$v.editEngine.$anyDirty) {
        this.$v.editEngine.$reset()
        this.$emit('on-cancel', this.engine)
      } else {
        this.$root.$emit('activateOverlay', 'ConfirmOverlay', {
          confirmText: this.$tc('messages.confirmCancel'),
          onConfirm: () => {
            this.$root.$emit('closeOverlay')
            this.$v.editEngine.$reset()
            this.$emit('on-cancel', this.engine)
          },
          onConfirmArgs: {},
        })
      }
    },
    confirmSave() {
      this.$root.$emit('activateOverlay', 'ConfirmOverlay', {
        confirmText: this.$t('messages.confirmEngineSave'),
        onConfirm: () => this.saveEngine(this.editEngine),
        onConfirmArgs: {},
      })
    },
    updateEngine(engine) {
      this.$apollo
        .mutate({
          mutation: ASSET_UPDATE_ENGINES_MUTATION,
          variables: {
            data: {
              id: engine?.id,
              serialNumber: engine?.serialNumber,
              makeId: engine.makeId,
              modelId: engine?.model?.id,
              energySource: engine?.energySource,
            },
          },
        })
        .then(() => {
          this.$v.editEngine.$reset()
          this.$emit('on-save')
        })
        .catch(error => {
          this.pageErrors = error?.graphQLErrors[0]?.message
        })
        .finally(() => {
          this.$root.$emit('closeOverlay')
        })
    },
    createEngine(engine) {
      this.$apollo
        .mutate({
          mutation: ASSET_CREATE_ENGINES_MUTATION,
          variables: {
            data: {
              machineId: engine.assetId,
              serialNumber: engine.serialNumber,
              ecu: {
                partNumber: engine.ecu?.partNumber,
              },
              makeId: engine.makeId,
              modelId: engine.model?.id,
              energySource: engine.energySource,
            },
          },
        })
        .then(() => {
          this.$v.editEngine.$reset()
          this.$emit('on-save')
        })
        .catch(error => {
          this.pageErrors = error?.graphQLErrors[0]?.message
        })
        .finally(() => {
          this.$root.$emit('closeOverlay')
        })
    },
    saveEngine(engine) {
      engine.id === undefined ? this.createEngine(engine) : this.updateEngine(engine)
    },
    convertEnergySources(energySources) {
      if (!energySources) {
        return []
      }
      const titleCase = str => {
        return str
          .toLowerCase()
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      }
      return energySources.map(source => {
        const convertedCase = titleCase(source.name)
        return { source: source.name, name: convertedCase }
      })
    },
  },
  apollo: {
    engineMake: {
      query: ASSET_ENGINES_MAKE_QUERY,
    },
    engineModels: {
      query: ASSET_ENGINES_MODELS_QUERY,
    },
    energySources: {
      query: ASSET_ENERGY_SOURCE_QUERY,
      result({ data }) {
        this.mappedEnergySources = this.convertEnergySources(data?.energySources?.enumValues)
      },
    },
  },
}
</script>
