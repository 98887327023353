<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <EngineDetailsStyled v-else>
    <div>
      <h3>{{ $t('enginesManager.heading') }}</h3>
    </div>
    <EngineManagerStyled v-for="(engine, index) in assetEngines.engines" :key="engine.id">
      <div v-if="editingEngineIndex !== index">
        <h4>{{ $t('enginesManager.nthEngineHeading', { nth: index + 1 }) }}</h4>
        <InputField :labelWidth="15">
          <template v-slot:input>
            <input type="text" :value="engine.serialNumber" :disabled="true" />
          </template>
          <template v-slot:label>{{ $t('enginesManager.serialNumber.label') }}</template>
        </InputField>
        <InputField v-if="engine.ecu && engine.ecu.partNumber" :labelWidth="15">
          <template v-slot:input>
            <input type="text" :value="engine.ecu.partNumber" :disabled="true" />
          </template>
          <template v-slot:label> {{ $t('enginesManager.ecu.partNumber') }} </template>
        </InputField>
        <InputField :labelWidth="15">
          <template v-slot:input>
            <input type="text" :value="engine.make" :disabled="true" />
          </template>
          <template v-slot:label> {{ $t('enginesManager.make.name') }} </template>
        </InputField>
        <InputField :labelWidth="15">
          <template v-slot:input>
            <input type="text" :value="engine.model.name" :disabled="true" />
          </template>
          <template v-slot:label> {{ $t('enginesManager.model.name') }} </template>
        </InputField>
        <InputField :labelWidth="15">
          <template v-slot:input>
            <input type="text" :value="titleCase(engine.energySource)" :disabled="true" />
          </template>
          <template v-slot:label> {{ $t('enginesManager.energy.source') }} </template>
        </InputField>
        <EngineManagerActionsStyled>
          <ButtonSolid :disabled="editingEngineIndex" @click="showEngineForm(index + 1)">
            <template>{{ $tc('enginesManager.updateEngine') }}</template>
            <Edit2Icon class="edit-icon" />
          </ButtonSolid>
        </EngineManagerActionsStyled>
      </div>
      <div v-else-if="editingEngineIndex === index">
        <EngineForm :engine="cloneEngine(engine)" @on-cancel="closeForm" @on-save="refreshEngines" />
      </div>
    </EngineManagerStyled>
    <EngineManagerActionsStyled>
      <ButtonSolid :disabled="editingEngineIndex" @click="addNewAssetEngine" class="add-action">
        <template>
          {{ assetEngines.engines?.length === 0 ? $t('enginesManager.addEngine') : $t('enginesManager.addAnotherEngine') }}
        </template>
        <PlusCircleIcon class="add-icon" />
      </ButtonSolid>
    </EngineManagerActionsStyled>
    <div class="button-group"></div>
  </EngineDetailsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { PlusCircleIcon, Edit2Icon } from 'vue-feather-icons'
import chroma from 'chroma-js'
import { FlashMessages } from '@common/singletons'
import { ButtonSolid } from '@styles/buttons'
import { InputField, TalpaLoaderWrapper } from '@common/components'
import EngineForm from './EngineForm'
import ASSET_ENGINES_QUERY from '#/graphql/assetEngines/engineDetails.gql'

const EngineDetailsStyled = styled('div')`
  grid-area: engine-details;
  padding: 1rem;
  margin-bottom: 1rem;
  overflow-x: auto;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};

  .add-icon {
    padding-left: 0.2rem;
  }
  .edit-icon {
    padding-left: 0.3rem;
  }
`
const EngineManagerStyled = styled('div')`
  min-height: 22rem;
  padding: 0.25rem 1rem;
  margin-bottom: 1rem;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(0.5).alpha(0.5).css()};
`
const EngineManagerActionsStyled = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  margin-bottom: 1rem;
`

export default {
  props: {
    assetID: {
      type: String,
    },
  },
  components: {
    EngineDetailsStyled,
    EngineManagerStyled,
    EngineManagerActionsStyled,
    InputField,
    ButtonSolid,
    TalpaLoaderWrapper,
    PlusCircleIcon,
    Edit2Icon,
    EngineForm,
  },
  data: () => {
    return {
      assetEngines: [],
      // it is the index pointing to the assetEngines array
      // for new engine it is the last index always.
      editingEngineIndex: undefined,
      newEngine: {
        id: undefined,
        serialNumber: '',
        ecu: {
          partNumber: '',
        },
        make: '',
        makeId: null,
        model: {
          id: null,
          name: '',
        },
        energySource: '',
      },
    }
  },
  methods: {
    addNewAssetEngine() {
      // add assetId here because assetId is not available while creation on newEngine in data
      this.assetEngines.engines.push(this.cloneEngine({ assetId: this?.assetID, ...this.newEngine }))
      this.editingEngineIndex = this.assetEngines.engines.length - 1
    },
    cloneEngine(engine) {
      return JSON.parse(JSON.stringify(engine))
    },
    closeForm(engine) {
      if (this.isFormBeingEdited(this.editingEngineIndex) && this.isEngineNew(engine)) {
        // remove the new engine from the list with id as undefined
        this.assetEngines.engines.findIndex((engine, index) => {
          if (engine.id === undefined) {
            this.assetEngines.engines.splice(index, 1)
            return true
          }
        })
      }
      this.editingEngineIndex = undefined
    },
    isFormBeingEdited(index) {
      return index !== undefined
    },
    isEngineNew(engine) {
      return engine?.id === undefined
    },
    refreshEngines() {
      const engine = this.assetEngines.engines[this.editingEngineIndex]
      const messageKey = engine.id ? 'messages.entityUpdatedSuccessfully' : 'messages.entityCreatedSuccessfully'
      FlashMessages.$emit('success', this.$t(messageKey, { entity: this.$t('enginesManager.engine') }), {
        timeout: 3000,
      })
      this.$apollo.queries.assetEngines.refetch()
      this.editingEngineIndex = undefined
    },
    showEngineForm(index) {
      this.editingEngineIndex = index - 1
    },
    titleCase(str) {
      return str
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
  },
  apollo: {
    assetEngines: {
      query: ASSET_ENGINES_QUERY,
      variables() {
        return {
          where: {
            id: this.assetID,
          },
        }
      },
    },
  },
}
</script>
