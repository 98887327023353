var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.asset ? _c('ModelEditor', {
    attrs: {
      "title": _vm.title,
      "model": _vm.asset,
      "errors": _vm.errors,
      "canSave": _vm.isDirty,
      "canCancel": _vm.isDirty,
      "loading": _vm.$apollo.loading
    },
    on: {
      "save": _vm.save,
      "cancel": _vm.restore
    }
  }, [_c('EditAssetStyled', [_c('AssetStyled', {
    staticClass: "asset"
  }, [_c('AutoForm', {
    attrs: {
      "model": _vm.asset,
      "modelName": 'Machine',
      "filterFields": _vm.filterFields,
      "disabledFields": _vm.disabledFields
    },
    on: {
      "update:model": function updateModel($event) {
        _vm.asset = $event;
      }
    }
  }), _c('div', {
    staticClass: "type"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$tc('assetType', 1)) + " ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.assetTypes,
      "value": _vm.selectedAssetType,
      "trackBy": "id",
      "label": "name",
      "disabled": true
    },
    on: {
      "select": _vm.setAssetType
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$tc('assetModel', 1)) + " ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.assetModels,
      "value": _vm.selectedAssetModel,
      "trackBy": "id",
      "label": "name",
      "disabled": true
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$tc('utilizationCategory', 1)) + " ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.assetUtilizationCategories,
      "value": _vm.selectedAssetUtilizationCategories,
      "multiple": true,
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.addSelectedAssetUtilizationCategories,
      "remove": _vm.removeSelectedAssetUtilizationCategories
    }
  })], 1), _c('div', {
    staticClass: "oem"
  }, [_c('span', [_vm._v(" Manufacturer ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.organizations,
      "value": _vm.selectedOEM,
      "trackBy": "id",
      "label": "name",
      "disabled": _vm.$apollo.queries.asset.loading,
      "loading": _vm.$apollo.queries.asset.loading
    },
    on: {
      "select": _vm.setOEM
    }
  })], 1)], 1), _c('LoggerAssetHistory', {
    staticClass: "asset-logger-list",
    attrs: {
      "assetID": _vm.$route.params.id,
      "title": 'Logger History'
    }
  }), _c('AssetLoggerManager', {
    staticClass: "logger-asset-manager",
    attrs: {
      "mode": 'asset',
      "title": 'Asset Logger Manager'
    }
  }), _c('EngineDetails', {
    staticClass: "engine-details",
    attrs: {
      "assetID": _vm.$route.params.id
    }
  }), _c('WheelAssetManager', {
    staticClass: "asset-wheel-relation",
    attrs: {
      "assetID": _vm.$route.params.id,
      "title": 'Asset Wheel Position Manager'
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }