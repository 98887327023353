var render = function () {
  var _vm$editEngine;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('div', [_c('header', [_c('h4', [_vm._v(_vm._s((_vm$editEngine = _vm.editEngine) !== null && _vm$editEngine !== void 0 && _vm$editEngine.id ? _vm.$tc('enginesManager.updateEngine') : _vm.$tc('enginesManager.addEngine')))])]), _c('div', [_c('InputField', {
    attrs: {
      "labelWidth": 15
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.editEngine.serialNumber.$model,
            expression: "$v.editEngine.serialNumber.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "required": true,
            "disabled": _vm.editEngine.id
          },
          domProps: {
            "value": _vm.$v.editEngine.serialNumber.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.editEngine.serialNumber, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.$tc('enginesManager.serialNumber.label')) + "*")];
      },
      proxy: true
    }, _vm.$v.editEngine.serialNumber.$dirty && !_vm.$v.editEngine.serialNumber.required ? {
      key: "errors",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$tc('enginesManager.serialNumber.validations.required')) + " ")];
      },
      proxy: true
    } : null], null, true)
  }), _vm.isPartNumberVisible ? _c('InputField', {
    attrs: {
      "labelWidth": 15
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.editEngine.ecu.partNumber.$model,
            expression: "$v.editEngine.ecu.partNumber.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "disabled": _vm.isPartNumberPresent
          },
          domProps: {
            "value": _vm.$v.editEngine.ecu.partNumber.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.editEngine.ecu.partNumber, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.$tc('enginesManager.ecu.partNumber')))];
      },
      proxy: true
    }], null, false, 3836232285)
  }) : _vm._e(), _c('StyledDropDown', [_c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v(_vm._s(_vm.$t('enginesManager.make.name')) + "*")]), _c('Multiselect', {
    staticClass: "multiselect",
    attrs: {
      "value": _vm.selectedMake,
      "placeholder": _vm.$t('placeholders.selectOption'),
      "track-by": "id",
      "label": "code",
      "options": _vm.engineMake,
      "searchable": true,
      "multiple": false,
      "loading": false,
      "internal-search": true,
      "clear-on-select": false,
      "close-on-select": true,
      "options-limit": 300,
      "limit": 3,
      "max-height": 200,
      "show-no-results": false,
      "hide-selected": true,
      "show-labels": false
    },
    on: {
      "select": function select($event) {
        return _vm.setMake($event);
      }
    }
  }), _c('div', {
    staticClass: "add-icon",
    on: {
      "click": _vm.showEngineMakeOverlay
    }
  }, [_c('PlusCircleIcon')], 1)], 1)]), _vm.$v.editEngine.makeId.$dirty && !_vm.$v.editEngine.makeId.required ? _c('p', {
    staticClass: "errors"
  }, [_vm._v(" " + _vm._s(_vm.$tc('messages.required')) + " ")]) : _vm._e(), _c('StyledDropDown', [_c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v(_vm._s(_vm.$tc('enginesManager.model.name', 1)) + "*")]), _c('Multiselect', {
    staticClass: "multiselect",
    attrs: {
      "value": _vm.selectedModel,
      "placeholder": _vm.$t('placeholders.selectOption'),
      "track-by": "id",
      "label": "name",
      "options": _vm.engineModels,
      "searchable": true,
      "multiple": false,
      "loading": false,
      "internal-search": true,
      "clear-on-select": false,
      "close-on-select": true,
      "options-limit": 300,
      "limit": 3,
      "max-height": 200,
      "show-no-results": false,
      "hide-selected": true,
      "show-labels": false
    },
    on: {
      "select": function select($event) {
        return _vm.setModel($event);
      }
    }
  }), _c('div', {
    staticClass: "add-icon",
    on: {
      "click": _vm.showEngineModelOverlay
    }
  }, [_c('PlusCircleIcon')], 1)], 1)]), _vm.$v.editEngine.model.id.$dirty && !_vm.$v.editEngine.model.id.required ? _c('p', {
    staticClass: "errors"
  }, [_vm._v(" " + _vm._s(_vm.$tc('messages.required')) + " ")]) : _vm._e(), _c('StyledDropDown', [_c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v(_vm._s(_vm.$tc('enginesManager.energy.source', 1)) + "*")]), _c('Multiselect', {
    staticClass: "multiselect",
    attrs: {
      "value": _vm.selectedEnergySource,
      "placeholder": _vm.$t('placeholders.selectOption'),
      "track-by": "source",
      "label": "name",
      "options": _vm.mappedEnergySources,
      "searchable": true,
      "multiple": false,
      "loading": false,
      "internal-search": true,
      "clear-on-select": false,
      "close-on-select": true,
      "options-limit": 300,
      "limit": 3,
      "max-height": 200,
      "show-no-results": false,
      "hide-selected": true,
      "show-labels": false
    },
    on: {
      "select": function select($event) {
        return _vm.setEnergySource($event);
      }
    }
  })], 1)]), _vm.$v.editEngine.energySource.$dirty && !_vm.$v.editEngine.energySource.required ? _c('p', {
    staticClass: "errors"
  }, [_vm._v(" " + _vm._s(_vm.$tc('messages.required')) + " ")]) : _vm._e()], 1), _c('EngineActionsStyled', [_c('ButtonSolid', {
    staticClass: "add-action",
    attrs: {
      "disabled": _vm.isInvalid || _vm.isNotDirty
    },
    on: {
      "click": _vm.confirmSave
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('enginesManager.saveEngine')) + " ")]), _c('ButtonSolid', {
    staticClass: "add-action",
    on: {
      "click": _vm.cancelForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('actions.cancel')) + " ")]), _vm.pageErrors ? _c('p', [_c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.$tc('error')) + ": ")]), _vm._v(" " + _vm._s(_vm.pageErrors) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }