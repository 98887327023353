<template>
  <ModelEditor
    v-if="asset"
    :title="title"
    :model="asset"
    :errors="errors"
    :canSave="isDirty"
    :canCancel="isDirty"
    :loading="$apollo.loading"
    @save="save"
    @cancel="restore"
  >
    <EditAssetStyled>
      <AssetStyled class="asset">
        <AutoForm :model.sync="asset" :modelName="'Machine'" :filterFields="filterFields" :disabledFields="disabledFields" />
        <div class="type">
          <span>
            {{ $tc('assetType', 1) }}
          </span>
          <Multiselect :options="assetTypes" :value="selectedAssetType" trackBy="id" label="name" @select="setAssetType" :disabled="true" />
        </div>
        <div class="type">
          <span>
            {{ $tc('assetModel', 1) }}
          </span>
          <Multiselect :options="assetModels" :value="selectedAssetModel" trackBy="id" label="name" :disabled="true" />
        </div>
        <div class="type">
          <span>
            {{ $tc('utilizationCategory', 1) }}
          </span>
          <Multiselect
            :options="assetUtilizationCategories"
            :value="selectedAssetUtilizationCategories"
            :multiple="true"
            trackBy="id"
            label="name"
            @select="addSelectedAssetUtilizationCategories"
            @remove="removeSelectedAssetUtilizationCategories"
          />
          <!-- <div class="add-icon">
          <PlusCircleIcon @click="$root.$emit('activateOverlay', 'CreateAssetTypeOverlay')" />
        </div> -->
        </div>
        <div class="oem">
          <span> Manufacturer </span>
          <Multiselect
            :options="organizations"
            :value="selectedOEM"
            trackBy="id"
            label="name"
            @select="setOEM"
            :disabled="$apollo.queries.asset.loading"
            :loading="$apollo.queries.asset.loading"
          />
        </div>
      </AssetStyled>
      <LoggerAssetHistory class="asset-logger-list" :assetID="$route.params.id" :title="'Logger History'" />
      <AssetLoggerManager class="logger-asset-manager" :mode="'asset'" :title="'Asset Logger Manager'" />
      <EngineDetails class="engine-details" :assetID="$route.params.id" />
      <WheelAssetManager class="asset-wheel-relation" :assetID="$route.params.id" :title="'Asset Wheel Position Manager'" />
    </EditAssetStyled>
  </ModelEditor>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import isEqual from 'lodash/isEqual'
import ModelEditor from '@/components/misc/ModelEditor'
import EngineDetails from '@/components/Engines/EngineDetails'
import LoggerAssetHistory from '@/components/Loggers/LoggerAssetHistory'
import { AutoForm, WheelAssetManager, AssetLoggerManager } from '@common/components'
import { FlashMessages } from '@common/singletons'
import Multiselect from 'vue-multiselect'
import { flexCenter } from '@styles/mixins'
import ASSET_QUERY from '#/graphql/operations/asset/assetBasic.gql'
import ASSET_TYPES_QUERY from '#/graphql/assetTypes/assetTypes.gql'
import ORGANIZATIONS_QUERY from '#/graphql/organizations/organizations.gql'
import UPDATE_ASSET_MUTATION from '#/graphql/operations/asset/updateAsset.gql'
import ASSET_MODELS_QUERY from '#/graphql/assetModels/assetModels.gql'
import ASSET_UTILIZATION_CATEGORIES_QUERY from '#/graphql/assetUtilizationCategories/assetUtilizationCategories.gql'

const AssetStyled = styled('div')`
  grid-area: asset;
  .type,
  .oem {
    display: flex;
    align-items: center;
    > span {
      margin-left: 2.5rem;
    }
    .multiselect {
      max-width: 20rem;
      margin: 1rem;
    }
  }
  .add-icon {
    ${flexCenter}
    width: 40px;
    height: 40px;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
    &:hover {
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
    }
  }
  input {
    max-width: 50%;
  }
`

const EditAssetStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 59rem;
  grid-gap: 1rem;
  grid-template-areas:
    'asset asset-logger-list'
    'logger-asset-manager logger-asset-manager'
    'engine-details engine-details'
    'asset-wheel-relation asset-wheel-relation';
`

export default {
  components: {
    AssetStyled,
    ModelEditor,
    AutoForm,
    Multiselect,
    // PlusCircleIcon,
    WheelAssetManager,
    AssetLoggerManager,
    EditAssetStyled,
    LoggerAssetHistory,
    EngineDetails,
  },
  data() {
    return {
      asset: null,
      assetLocal: null,
      selectedOEMId: null,
      errors: [],
      assetTypes: [],
      assetModels: [],
      assetUtilizationCategories: [],
      organizations: [],
      disabledFields: ['numericId'],
      filterFields: ['lastLog', 'lastPosition', 'oem', 'type', 'tmid', 'cuid', 'currentArpHeightCm', 'manufacturer', 'manufacturerCuid'],
    }
  },
  computed: {
    title() {
      return `Asset ${this.asset?.name ?? ''}`
    },
    isDirty() {
      return !isEqual(this.asset, this.assetLocal)
    },
    selectedAssetType() {
      const id = this.asset?.type?.id
      const s = this.assetTypes.find(f => f.id === id)
      return s
    },
    selectedAssetUtilizationCategories() {
      const categories = this.asset?.utilizationCategories || []
      const categoryNames = categories.map(m => m)
      return this.assetUtilizationCategories.filter(c => categoryNames.includes(c.name))
    },
    selectedAssetModel() {
      const id = this.asset?.model?.id
      const s = this.assetModels.find(f => f.id === id)
      return s
    },
    selectedOEM() {
      const id = this.selectedOEMId
      return this.organizations.find(f => f.id === id)
    },
    selectedOrganizationHeavyEquipment() {
      const id = this.asset?.manufacturerCuid
      return this.organizations.find(f => f.id === id)
    },
  },
  methods: {
    setAssetType(type) {
      this.$apollo.mutate({
        mutation: UPDATE_ASSET_MUTATION,
        variables: {
          where: {
            id: this.$route.params.id,
          },
          data: {
            type: {
              connect: {
                id: type.id,
              },
            },
          },
        },
      })
    },
    async setOEM(organization) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_ASSET_MUTATION,
          variables: {
            where: {
              id: this.asset.id,
            },
            data: {
              manufacturerCuid: organization.id,
            },
          },
          update: (store, { data }) => {
            FlashMessages.$emit('success', 'Manufacturer updated successfully')
            this.updateAssetOem(store, data?.updateMachine?.oem)
          },
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async updateAssetOem(store, oem) {
      this.selectedOEMId = oem.id
      const { asset } = store.readQuery({
        query: ASSET_QUERY,
        variables: {
          id: this.$route.params.id,
        },
      })
      if (oem) {
        asset.oem = oem
        store.writeQuery({
          query: ASSET_QUERY,
          variables: {
            id: this.$route.params.id,
          },
          data: {
            asset,
          },
        })
      }
    },
    async save() {
      if (!this.asset) {
        return
      }
      const asset = { ...this.asset }

      const data = {
        serialNumber: asset.serialNumber,
      }
      if (asset.name) {
        data.name = asset.name
      }
      if (asset.customerId) {
        data.customerId = asset.customerId
      }
      if (asset.notes) {
        data.notes = asset.notes
      }
      if (asset.constructionDate) {
        data.constructionDate = asset.constructionDate
      }
      if (asset.isVisible !== undefined) {
        data.isVisible = asset.isVisible
      }
      try {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_ASSET_MUTATION,
          variables: {
            where: {
              id: asset.id,
            },
            data,
          },
        })
        this.assetLocal = res?.data.updateMachine ?? null
        FlashMessages.$emit('success', `Asset ${this.assetLocal.name} saved successfully`, {
          timeout: 3000,
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async restore() {
      this.asset = this.assetLocal
    },
    async addSelectedAssetUtilizationCategories({ name }) {
      const assetId = this.$route.params.id
      this.asset.utilizationCategories.push(name)
      await this.$apollo.mutate({
        mutation: UPDATE_ASSET_MUTATION,
        variables: {
          where: {
            id: assetId,
          },
          data: {
            id: assetId,
            utilizationCategories: this.asset.utilizationCategories,
          },
        },
        update: store => {
          const { asset } = store.readQuery({
            query: ASSET_QUERY,
            variables: {
              id: this.$route.params.id,
            },
          })

          asset.utilizationCategories.push(name)

          store.writeQuery({
            query: ASSET_QUERY,
            variables: {
              id: this.$route.params.id,
            },
            data: {
              asset,
            },
          })
        },
      })
    },
    async removeSelectedAssetUtilizationCategories({ name }) {
      const assetId = this.$route.params.id
      this.asset.utilizationCategories = this.asset.utilizationCategories.filter(c => c !== name)

      await this.$apollo.mutate({
        mutation: UPDATE_ASSET_MUTATION,
        variables: {
          where: {
            assetId,
          },
          data: {
            id: assetId,
            utilizationCategories: this.asset.utilizationCategories,
          },
        },
        update: store => {
          const { asset } = store.readQuery({
            query: ASSET_QUERY,
            variables: {
              id: assetId,
            },
          })

          asset.utilizationCategories = this.asset.utilizationCategories

          store.writeQuery({
            query: ASSET_QUERY,
            variables: {
              id: assetId,
            },
            data: {
              asset,
            },
          })
        },
      })
    },
  },
  apollo: {
    assetTypes: {
      query: ASSET_TYPES_QUERY,
    },
    organizations: {
      query: ORGANIZATIONS_QUERY,
      variables: {
        where: {
          isOEM: true,
        },
      },
    },
    assetModels: {
      query: ASSET_MODELS_QUERY,
    },
    assetUtilizationCategories: {
      query: ASSET_UTILIZATION_CATEGORIES_QUERY,
      result({ data }) {
        this.assetUtilizationCategories = data?.assetUtilizationCategories?.enumValues?.map((category, index) => ({
          name: category.name,
          id: index++,
        }))
      },
    },
    asset: {
      query: ASSET_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      manual: true,
      result({ data }) {
        this.asset = data?.asset
        this.assetLocal = data?.asset
        this.selectedOEMId = data?.asset?.oem?.id || null
      },
    },
  },
}
</script>
